<script>
import { mapActions } from 'vuex'
import managementMixin from '@/modules/Management/managementMixin'

export default {
  name: 'ManagementBanners',
  components: {
    ContentHeader: () => import('@/components/general/ContentHeader'),
    Tabs: () => import('@/components/general/Tabs'),
    Action: () => import('@/components/general/Action'),
    FilterList: () => import('@/components/general/FilterList'),
    FilterListSearch: () => import('@/components/general/FilterListSearch'),
    Datatable: () => import('@/components/general/Datatable'),
    Pagination: () => import('@/components/general/Pagination'),
    Dropdown: () => import('@/components/general/Dropdown'),
    DropdownLink: () => import('@/components/general/DropdownLink'),
    ModalConfirm: () => import('@/components/macro/ModalConfirm')
  },

  mixins: [ managementMixin ],

  data () {
    return {
      pagination: {
        query: {},
        filter: {},
        order: {},
        page: 1,
        limit: 12,
        lastPage: null
      },

      modalRemoveOpen: false,
      bannerId: null
    }
  },

  computed: {
    bannersList () {
      return this.$store.getters.bannersList
    }
  },

  watch: {
    'pagination.page' () {
      this.getBannersList()
    }
  },

  created () {
    this.getBannersList()
  },

  methods: {
    ...mapActions([
      'setFetching',
      'setFeedback',
      'attemptGetBannersList',
      'attemptToggleBannerActive',
      'attemptRemoveBanner'
    ]),

    createNewBanner () {
      this.$router.push({ name: 'management.banners.edit' })
    },

    edit (id) {
      this.$router.push({
        name: 'management.banners.edit',
        params: { id: id }
      })
    },

    searchBanner (search) {
      this.pagination.query = {
        title: search
      }

      this.getBannersList()
    },

    getBannersList () {
      this.setFetching(true)

      this.attemptGetBannersList(this.pagination).then(pagination => {
        this.pagination.lastPage = pagination.lastPage
      }).finally(() => {
        this.setFetching(false)
      })
    },

    dateFormat (datetime) {
      if (datetime === '' || datetime === undefined || datetime === null) {
        return datetime
      }

      const date = new Date(Date.parse(datetime))

      return Intl.DateTimeFormat('pt-BR').format(date)
    },

    prevPage () {
      if (this.pagination.page > 1) {
        this.pagination.page -= 1
      }
    },

    nextPage () {
      this.pagination.page += 1
    },

    firstPage () {
      this.pagination.page = 1
    },

    lastPage () {
      this.pagination.page = this.pagination.lastPage
    },

    toggleActive (bannerId, toggleFlag) {
      this.setFetching(true)

      this.attemptToggleBannerActive(bannerId).then(() => {
        this.getBannersList()

        if (!toggleFlag) {
          this.setFeedback({ message: this.$t('management.banner:feedback.toggle.activate.success') })
        } else {
          this.setFeedback({ message: this.$t('management.banner:feedback.toggle.deactivate.success') })
        }
      }).catch(() => {
        this.setFeedback({ message: this.$t('global.error') })
      }).finally(() => {
        this.setFetching(false)
      })
    },

    remove () {
      this.modalRemoveOpen = false
      this.setFetching(true)

      this.attemptRemoveBanner(this.bannerId).then(() => {
        this.setFeedback({ message: this.$t('management.banner:feedback.remove.success') })
        this.getBannersList()
      }).catch(() => {
        this.setFeedback({ message: this.$t('management.banner:feedback.remove.error') })
      }).finally(() => {
        this.setFetching(false)
      })
    },

    confirmRemove (bannerId) {
      this.bannerId = bannerId
      this.modalRemoveOpen = true
    },

    cancelRemove () {
      this.modalRemoveOpen = false
    }
  }
}
</script>

<template>
  <div class="main-content management-banner-list">
    <ContentHeader
      :title="$t('management:header.title')"
      :description="$t('management:header.description')"
      background="/assets/images/themes/default/png/management_banner.png"
    >
      <Tabs
        slot="tabs"
        dark
        :links="availableTabLink"
      />
    </ContentHeader>
    <FilterList>
      <Action
        slot="button"
        type="button"
        primary
        large
        fixed-width
        :text="$t('management.banner:btn.add')"
        @click="createNewBanner"
      />
      <!--
 TODO: FilterOrder
-->
      <FilterListSearch
        slot="search"
        hide-error-details
        @search="searchBanner"
      />
    </FilterList>
    <div class="center">
      <Datatable :items="bannersList">
        <template slot="thead">
          <tr>
            <!--
            <th class="th">{{ $t('management.banner.list:datatable.header.1') }}</th>
-->
            <th class="th">
              {{ $t('management.banner.list:datatable.header.2') }}
            </th>
            <th class="th">
              {{ $t('management.banner.list:datatable.header.5') }}
            </th>
            <th
              class="th td-text"
              width="200"
            >
              {{ $t('management.banner.list:datatable.header.3') }}
            </th>
            <th
              class="th"
              width="120"
            >
              {{ $t('management.banner.list:datatable.header.4') }}
            </th>
            <th
              class="th"
              width="40"
            />
          </tr>
        </template>
        <template
          slot="tbody"
          slot-scope="props"
        >
          <tr>
            <!--
            <td class="td">
-->
            <!--
              <span class="td-text bolder">{{ props.item.title }}</span>
-->
            <!--
            </td>
-->
            <td class="td">
              <img
                class="image-cell"
                :src="props.item.image"
              >
            </td>
            <td class="td">
              <span class="td-text">{{ props.item.order }}</span>
            </td>
            <td class="td">
              <span class="td-text">{{ dateFormat(props.item.createdAt) }}</span>
            </td>
            <td class="td">
              <span
                v-if="props.item.active"
                class="td-tag active-ribbon"
              >{{ $t('management.list:active') }}</span>
              <span
                v-else
                class="td-tag inactive-ribbon"
              >{{ $t('management.list:inactive') }}</span>
            </td>
            <td
              class="td td-actions"
              width="40"
            >
              <Dropdown
                icon="dots-vertical"
                right
              >
                <DropdownLink
                  :text="$t('global:edit')"
                  @click="edit(props.item.id)"
                />
                <DropdownLink
                  v-if="!props.item.active"
                  :text="$t('global:activate')"
                  @click="toggleActive(props.item.id, props.item.active)"
                />
                <DropdownLink
                  v-else
                  :text="$t('global:deactivate')"
                  @click="toggleActive(props.item.id, props.item.active)"
                />
                <DropdownLink
                  :text="$t('global:remove')"
                  @click="confirmRemove(props.item.id)"
                />
              </Dropdown>
            </td>
          </tr>
        </template>
      </Datatable>
      <Pagination
        :active-page="pagination.page"
        :page-count="pagination.lastPage"
        @firstPage="firstPage"
        @lastPage="lastPage"
        @nextPage="nextPage"
        @previousPage="prevPage"
      />
    </div>
    <ModalConfirm
      :active="modalRemoveOpen"
      :title="$t('management.banner:remove.title')"
      :description="$t('management.banner:remove.description')"
      @confirmAction="remove"
      @cancelAction="cancelRemove"
    />
  </div>
</template>

<style src="@/assets/styles/themes/default/management.css"></style>
